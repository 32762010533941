<template>
  <div class="CreateDebtor">
    <div class="column">
      <div class="CreateDebtor__header row row--justify-between">
        <label class="fs-22 fw-med">New Debtor</label>
        <base-button
          v-if="!prod"
          @click="hydrateFakeDebtorForTesting"
          class="bg-blue fc-white"
        >
          SHAZAM!!!
        </base-button>
      </div>

      <hr class="Divider">

      <div class="CreateDebtor__section-company column">
        <label class="fs-16 fw-med mb-15">Company</label>
        <div class="row row--wrap">
          <base-input
            v-model.trim="name.value"
            class="CreateDebtor__input--medium mr-9 mb-12"
            :instructions="instructions.SHORTENED_NAME_INSTRUCTION"
            :label="true"
            :label-for="'name'"
            :valid="name.valid"
          >
            Name
          </base-input>

          <base-input
            v-model.trim="dba.value"
            class="CreateClient__input--short mr-9 mb-25"
            :instructions="instructions.DBA_DEBTOR_INSTRUCTION"
            :label="true"
            :label-for="'dba'"
            :valid="dba.valid"
          >
            DBA
          </base-input>

          <!-- ADDRESS -->
          <base-input
            v-model.trim="address.value"
            class="CreateDebtor__input--long mr-9 mb-12"
            :instructions="instructions.ADDRESS_INSTRUCTION"
            :label="true"
            :label-for="'address'"
            :valid="address.valid"
          >
            Address
          </base-input>
          <base-input
            v-model.trim="address_2.value"
            class="CreateDebtor__input--short mr-9 mb-12"
            :instructions="instructions.ADDRESS_2_INSTRUCTION"
            :label="true"
            :label-for="'address 2'"
            :valid="address_2.valid"
          >
            Address 2
          </base-input>
          <base-input
            v-model.trim="city.value"
            class="CreateDebtor__input--short mr-9 mb-12"
            :instructions="instructions.CITY_INSTRUCTION"
            :label="true"
            :label-for="'city'"
            :valid="city.valid"
          >
            City
          </base-input>
          <div class="column column--width-auto mb-12">
            <label
              class="BaseInput__label uppercase"
              for="state"
            >
              State
            </label>
            <v-select
              v-model="state.value"
              aria-label="Select input to choose state"
              id="state"
              :class="['CreateDebtor__select-state mr-9', {
                'CreateDebtor__select-state--invalid': !state.valid
              }]"
              :clearable="false"
              :instructions="instructions.STATE_INSTRUCTION"
              :options="state.options"
              :placeholder="'State:'"
            />
          </div>
          <base-input
            v-model.trim="zip.value"
            class="CreateDebtor__input--short mr-9 mb-12"
            :instructions="instructions.ZIP_INSTRUCTION"
            :label="true"
            :label-for="'zip'"
            :valid="zip.valid"
          >
            Zip
          </base-input>

          <!-- PHONE -->
          <base-input
            v-model.trim="phone.value"
            class="CreateDebtor__input--short mr-9 mb-12"
            :instructions="
              phone.connected
                ? instructions.PHONE_INSTRUCTION
                : instructions.PHONE_CONNECTED_INSTRUCTION
            "
            :label="true"
            :label-for="'phone'"
            :valid="phone.valid && phone.connected"
          >
            Phone
          </base-input>

          <div class="column column--width-auto mb-12">
            <label
              class="BaseInput__label uppercase"
              for="bobtail-status"
            >
              Bobtail Status
            </label>

            <v-select
              v-model="bobtailStatus.value"
              aria-label="Select input to choose bobtail status"
              id="bobtail-status"
              class="CreateDebtor__select mr-9"
              :clearable="false"
              :options="bobtailStatus.options"
              :placeholder="'Status:'"
            />
          </div>
        </div>
      </div>

      <div class="CreateDebtor__section-authority column">
        <label class="fs-16 fw-med mb-15">Authority</label>
        <div class="row row--wrap">
          <!-- BROKER FIELDS -->
          <base-input
            v-model.trim="mc.value"
            class="CreateDebtor__input--short mr-9 mb-12"
            :instructions="
              mc.unique
                ? instructions.MC_INSTRUCTION
                : instructions.MC_UNIQUE_INSTRUCTION
            "
            :label="true"
            :label-for="'mc-number'"
            :valid="mc.valid && mc.unique"
          >
            MC #
          </base-input>
          <base-input
            v-model.trim="dot.value"
            class="CreateDebtor__input--short mr-9 mb-12"
            :instructions="
              dot.unique
                ? instructions.DOT_INSTRUCTION
                : instructions.DOT_UNIQUE_INSTRUCTION
            "
            :label="true"
            :label-for="'dot-number'"
            :valid="dot.valid && dot.unique"
          >
            DOT #
          </base-input>
          <div class="column column--width-auto">
            <label
              class="fc-light fs-12 mb-7 uppercase"
              for="authority-date"
            >
              {{ authorityDate.valid ? 'Authority Date' : instructions.AUTHORITY_DATE_INSTRUCTION }}
            </label>
            <base-date-picker
              v-model="authorityDate.value"
              class="mr-9 mb-12"
              id="authority-date"
              :valid="authorityDate.valid"
            />
          </div>
          <div class="column column--width-auto mb-12">
            <label
              class="BaseInput__label uppercase"
              for="authority-status"
            >
              Authority Status
            </label>

            <v-select
              v-model="authorityStatus.value"
              aria-label="Select input to choose authority status"
              id="authority-status"
              class="CreateDebtor__select mr-9"
              :clearable="false"
              :options="authorityStatus.options"
              :placeholder="'Status:'"
            />
          </div>
        </div>
      </div>

      <div class="CreateDebtor__section-credit column">
        <label class="fs-16 fw-med mb-15">Credit</label>
        <div class="row row--wrap">
          <!-- CREDIT RATING -->
          <div class="column column--width-auto mb-12">
            <label
              class="BaseInput__label uppercase"
              for="corporation-type"
            >
              {{ creditRating.valid ? 'Credit Rating' : 'Select a credit rating' }}
            </label>
            <v-select
              v-model="creditRating.value"
              aria-label="Select input to choose credit rating"
              id="rating"
              :class="['CreateDebtor__credit-select mr-9', {
                'CreateDebtor__credit-select--invalid': !creditRating.valid
              }]"
              :clearable="false"
              :options="creditRating.options"
              :placeholder="'Rating:'"
            />
          </div>
          <base-textarea
            v-model.trim="creditRatingReason.value"
            class="mb-10"
            :instructions="instructions.CREDIT_RATING_REASON_INSTRUCTION"
            :label="true"
            :label-for="'credit-rating-reason'"
            :rows="'2'"
            :valid="creditRatingReason.valid"
          >
            Rating Reason
          </base-textarea>
        </div>
      </div>

      <div class="CreateDebtor__section-delivery column">
        <div class="row row--wrap">
          <transition name="Transition__fade">
            <base-delivery-requirements
              v-if="currentDeliveryReqs"
              @save="setCurrentDeliveryReqs"
              :debtor="currentDeliveryReqs"
            />
          </transition>
        </div>
      </div>

      <hr class="Divider">

      <div class="CreateDebtor__save-wrap column column--align-center">
        <base-button
          @click="submitDebtor"
          class="CreateDebtor__save-button bg-blue fc-white"
          id="Testing__CreateDebtorSubmitBtn"
          :disabled="buttonDisabled || !isValid"
        >
          Save Debtor
        </base-button>
      </div>
    </div>

    <transition name="Transition__fade">
      <base-modal v-if="nonUniqueDebtorMCModalVisible">
        <template v-slot:label>
          A broker with this MC # already exists in the database. Are you sure you want to add them?
        </template>
        <template v-slot:actions>
          <base-button
            @click="nonUniqueDebtorMCModalVisible = false"
            class="bg-light fc-white mr-8"
          >
            Cancel
          </base-button>
          <base-button
            @click="submitDebtor(true)"
            class="bg-blue fc-white"
          >
            Yes, add them
          </base-button>
        </template>
      </base-modal>
    </transition>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import {
  Broker,
  Debtor
} from '../../../utils/api'
import displayErrorMessage from '../../../utils/errorMessages'
import { statesAbbreviations } from '../../../utils/constants'
import {
  formatPhoneNumber,
  formatStringCamelCase
} from '../../../utils/helpers'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseDatePicker from '../../../components/base-date-picker.vue'
import BaseDeliveryRequirements from '../../../components/base-delivery-requirements.vue'
import BaseInput from '../../../components/base-input.vue'
import BaseModal from '../../../components/base-modal.vue'
import BaseTextarea from '../../../components/base-textarea.vue'
// Mixins
import {
  ValidationMixin
} from '../../../utils/validation-mixin'

export default {
  name: 'CreateDebtor',

  components: {
    BaseButton,
    BaseDatePicker,
    BaseDeliveryRequirements,
    BaseInput,
    BaseModal,
    BaseTextarea,
  },

  mixins: [ValidationMixin],

  created () {
    this.setCurrentDeliveryReqs()
  },

  data () {
    return {
      address: {
        valid: false,
        value: '',
      },
      address_2: {
        valid: true,
        value: '',
      },
      authorityDate: {
        valid: true,
        value: null,
      },
      authorityStatus: {
        value: 'Authorized',
        options: ['Authorized', 'Not Authorized']
      },
      bobtailStatus: {
        value: 'Sandbox',
        options: ['Active', 'Sandbox', 'Inactive']
      },
      buttonDisabled: false,
      city: {
        valid: false,
        value: '',
      },
      creditRating: {
        options: ['A', 'B', 'C', 'D', 'F'],
        valid: false,
        value: '',
      },
      creditRatingReason: {
        valid: true,
        value: 'Initial Rating ',
      },
      currentDeliveryReqs: null,
      dba: {
        valid: true,
        value: '',
      },
      dot: {
        unique: true,
        valid: true,
        value: '',
      },
      mc: {
        unique: true,
        valid: true,
        value: '',
      },
      name: {
        valid: false,
        value: '',
      },
      noaEmailsValid: false,
      nonUniqueDebtorMCModalVisible: false,
      phone: {
        connected: true,
        valid: false,
        value: '',
      },
      phoneData: null,
      prod: process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'prodlocal',
      state: {
        options: statesAbbreviations,
        valid: false,
        value: '',
      },
      zip: {
        valid: false,
        value: '',
      },
    }
  },

  computed: {
    isValid () {
      if (!this.currentDeliveryReqs) return false

      let valid = true

      // DEBTOR
      // Only required: name, full address, phone
      // noa_email is "validated" in setCurrentDeliveryReqs
      if (!this.name.value || !this.isValidShortenedName(this.name.value)) {
        this.$set(this.name, 'valid', false)
        valid = false
      } else {
        this.$set(this.name, 'valid', true)
      }

      // DBA: not required
      if (this.dba.value && !this.isValidDBADebtor(this.dba.value)) {
        this.$set(this.dba, 'valid', false)
        valid = false
      } else {
        this.$set(this.dba, 'valid', true)
      }

      if (!this.address.value || !this.isValidAddress(this.address.value)) {
        this.$set(this.address, 'valid', false)
        valid = false
      } else {
        this.$set(this.address, 'valid', true)
      }

      if (this.address_2.value && !this.isValidAddress(this.address_2.value)) {
        this.$set(this.address_2, 'valid', false)
        valid = false
      } else {
        this.$set(this.address_2, 'valid', true)
      }

      if (!this.city.value || !this.isValidCity(this.city.value)) {
        this.$set(this.city, 'valid', false)
        valid = false
      } else {
        this.$set(this.city, 'valid', true)
      }

      if (!this.state.value || !this.isValidState(this.state.value)) {
        this.$set(this.state, 'valid', false)
        valid = false
      } else {
        this.$set(this.state, 'valid', true)
      }

      if (!this.zip.value || !this.isValidZip(this.zip.value)) {
        this.$set(this.zip, 'valid', false)
        valid = false
      } else {
        this.$set(this.zip, 'valid', true)
      }

      if (!this.phone.value || !this.isValidPhone(this.phone.value)) {
        this.$set(this.phone, 'valid', false)
        valid = false
      } else {
        this.$set(this.phone, 'valid', true)
      }

      // Required: credit rating & rating reason fields are required
      if (!this.creditRating.value || !this.isValidCreditRating(this.creditRating.value)) {
        this.$set(this.creditRating, 'valid', false)
        valid = false
      } else {
        this.$set(this.creditRating, 'valid', true)
      }

      if (
        !this.creditRatingReason.value
        || !this.isValidCreditRatingReason(this.creditRatingReason.value)
      ) {
        this.$set(this.creditRatingReason, 'valid', false)
        valid = false
      } else {
        this.$set(this.creditRatingReason, 'valid', true)
      }

      // BROKER
      // If mc, dot have input, all must have input or error
      // If all have input, create a new broker after creating the debtor

      // If 1 has value, check that all have value
      if (
        this.mc.value
        || this.dot.value
        || this.authorityDate.value
      ) {
        if (
          this.mc.value
          && this.dot.value
          && this.authorityDate.value
        ) {
          // Individually check each broker input for validation
          if (!this.isValidMC(this.mc.value)) {
            this.$set(this.mc, 'valid', false)
            valid = false
          } else {
            this.$set(this.mc, 'valid', true)
          }

          if (!this.isValidDOT(this.dot.value)) {
            this.$set(this.dot, 'valid', false)
            valid = false
          } else {
            this.$set(this.dot, 'valid', true)
          }

          if (!this.isValidAuthorityDate(this.authorityDate.value)) {
            this.$set(this.authorityDate, 'valid', false)
            valid = false
          } else {
            this.$set(this.authorityDate, 'valid', true)
          }
        } else {
          valid = false

          if (!this.mc.value) {
            this.$set(this.mc, 'valid', false)
          } else {
            this.$set(this.mc, 'valid', true)
          }

          if (!this.dot.value) {
            this.$set(this.dot, 'valid', false)
          } else {
            this.$set(this.dot, 'valid', true)
          }

          if (!this.authorityDate.value) {
            this.$set(this.authorityDate, 'valid', false)
          } else {
            this.$set(this.authorityDate, 'valid', true)
          }
        }
      } else {
        this.setBrokerFieldsValid()
      }

      return valid
    },
  },

  methods: {
    hydrateFakeDebtorForTesting () {
      if (this.prod) return
      this.$set(this.address, 'value', '456 Elm St')
      this.$set(this.city, 'value', 'Jacksonville')
      this.$set(this.creditRating, 'value', 'A')
      this.$set(this.state, 'value', 'Florida')
      this.$set(this.zip, 'value', '08935')
      this.$set(this.name, 'value', 'Jack\'s Pizza')
      this.$set(this.phone, 'value', '7654619920')
      this.$set(this.mc, 'value', 'm')
      this.$set(this.dot, 'value', '416123')
      this.$set(this.authorityDate, 'value', new Date('Mon Aug 03 2020 11:38:00 GMT-0400 (Eastern Daylight Time)'))
    },

    async isDOTMCUnique () {
      // BROKER
      // mc & dot must be unique - but only need to check them if all filled out
      let isUnique = true
      if (
        this.mc.value
        && this.dot.value
        && this.authorityDate.value
      ) {
        try {
          if (!(await Broker.uniqueField({ dot: this.dot.value })).data) {
            isUnique = false
            this.$set(this.dot, 'unique', false)
          } else {
            this.$set(this.dot, 'unique', true)
          }
          if (!(await Broker.uniqueField({ mc: this.mc.value })).data) {
            isUnique = false
            this.$set(this.mc, 'unique', false)
          } else {
            this.$set(this.mc, 'unique', true)
          }
        }
        catch (error) {
          this.captureSentryEvent(
            'Create Debtor "Unique"',
            {
              clientId: this.id,
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          // let the api error messages handle it
          this.CError(error)
          return true
        }
      }
      return isUnique
    },

    async isUniqueAndConnected () {
      let valid = true

      this.progressStart()

      // Check phone
      this.phoneData = await this.checkPhoneNumber(this.phone.value)
      // this.phoneData will be `false` if `this.phone.value` is not connected
      if (this.phoneData) this.$set(this.phoneData, 'phone', formatPhoneNumber(this.phone.value))
      if (!this.phoneData.phone_valid) {
        valid = false
        this.$set(this.phone, 'connected', false)
        this.progressFail()
      } else {
        this.$set(this.phone, 'connected', true)
      }

      // Check for unique MC and DOT values
      if (!(await this.isDOTMCUnique())) valid = false

      // lastly, check if all required fields are valid and MC is not unique
      if (this.shouldShowMCModal()) valid = false

      this.progressFinish()

      return valid
    },

    setBrokerFieldsValid () {
      this.$set(this.authorityDate, 'valid', true)
      this.$set(this.dot, 'valid', true)
      this.$set(this.mc, 'valid', true)
    },

    setCurrentDeliveryReqs (methods = null) {
      // Methods are passed by the base-delivery-requirements component when a user clicks "save"
      // Methods represent the delivery options a debtor has, such as Invoice Delivery Emails

      // Initialize in created(); All the values start as "null/false" when creating a debtor
      if (!methods) {
        this.currentDeliveryReqs = {
          fax: null,
          invoice_delivery_emails: null,
          mailing_address: null,
          mailing_address_2: null,
          mailing_city: null,
          mailing_state: null,
          mailing_zip: null,
          noa_emails: null,
          online_submit_url: null,
          pay_status_emails: null,
          require_copies: false,
          require_email: false,
          require_fax: false,
          require_online_submit: false,
          require_originals: false,
        }
        return
      }

      // When methods are not null (base-delivery-requirements is emitting the saved values), update
      // currentDeliveryReqs (should be a mix of the last made edit and invoice debtor (backup))
      this.currentDeliveryReqs = {
        fax: methods.fax.required
          ? methods.fax.value
          : this.currentDeliveryReqs.fax,
        invoice_delivery_emails: methods.invoice_delivery_emails.required
          ? methods.invoice_delivery_emails.value.toLowerCase().split(', ')
          : this.currentDeliveryReqs.invoice_delivery_emails,
        mailing_address: methods.mail.required.copies || methods.mail.required.originals
          ? methods.mail.value.address
          : this.currentDeliveryReqs.mailing_address,
        mailing_address_2: methods.mail.required.copies || methods.mail.required.originals
          ? methods.mail.value.address_2
          : this.currentDeliveryReqs.mailing_address_2,
        mailing_city: methods.mail.required.copies || methods.mail.required.originals
          ? methods.mail.value.city
          : this.currentDeliveryReqs.mailing_city,
        mailing_state: methods.mail.required.copies || methods.mail.required.originals
          ? methods.mail.value.state
          : this.currentDeliveryReqs.mailing_state,
        mailing_zip: methods.mail.required.copies || methods.mail.required.originals
          ? methods.mail.value.zip
          : this.currentDeliveryReqs.mailing_zip,
        // Required, do not need to check for existence
        noa_emails: methods.noa_emails.value.split(', '),
        online_submit_url: methods.online.required
          ? methods.online.value
          : this.currentDeliveryReqs.online_submit_url,
        // Not required by base-delivery-requirements, must check for existing value not "required"
        pay_status_emails: methods.pay_status_emails.value
          ? methods.pay_status_emails.value.toLowerCase().split(', ')
          : this.currentDeliveryReqs.pay_status_emails,
        require_copies: methods.mail.required.copies,
        require_email: methods.invoice_delivery_emails.required,
        require_fax: methods.fax.required,
        require_online_submit: methods.online.required,
        require_originals: methods.mail.required.originals,
      }
      // Delivery requirements cannot be saved if a valid noa_email does not exist, so it's safe
      // to set this to true here. It also can't be edited and saved if noa_email isn't valid
      this.noaEmailsValid = true
    },

    shouldShowMCModal () {
      // If the ONLY thing wrong is the MC# isn't unique, nonUniqueDebtorMCModalVisible = true
      if (
        this.isValid
        && this.mc.unique === false
        // dot uniqueness and phone connectivity aren't covered in this.isvalid
        && this.dot.unique
        && this.phone.connected
      ) {
        this.nonUniqueDebtorMCModalVisible = true
        return true
      }
      return false
    },

    async submitDebtor (skipValidation = false) {
      this.progressStart()
      this.buttonDisabled = true

      // Only time validation is skipped is after validation has ran and the
      // "MC # is not unique" modal is visible, which has a button with submitDebtor(true)
      if (!skipValidation) {
        if (!this.isValid || !await this.isUniqueAndConnected()) {
          this.buttonDisabled = false
          return
        }
      }

      // Remove all leading 0s from MC
      const doctoredMC = this.mc.value.replace(/^0+/, '')

      try {
        const newDebtor = (await Debtor.create({
          address: formatStringCamelCase(this.address.value),
          address_2: formatStringCamelCase(this.address_2.value),
          city: formatStringCamelCase(this.city.value),
          doing_business_as: this.dba.value
            ? this.dba.value.toLowerCase().split(', ')
            : [],
          fax: this.currentDeliveryReqs.fax,
          invoice_delivery_emails: this.currentDeliveryReqs.invoice_delivery_emails,
          mailing_address: this.currentDeliveryReqs.mailing_address,
          mailing_address_2: this.currentDeliveryReqs.mailing_address_2,
          mailing_city: this.currentDeliveryReqs.mailing_city,
          mailing_state: this.currentDeliveryReqs.mailing_state,
          mailing_zip: this.currentDeliveryReqs.mailing_zip,
          // Always store debtor (legal) name in lowercase for full control over how to display
          // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639
          name: this.name.value.toLowerCase(),
          noa_emails: this.currentDeliveryReqs.noa_emails,
          online_submit_url: this.currentDeliveryReqs.online_submit_url,
          pay_status_emails: this.currentDeliveryReqs.pay_status_emails,
          phone: this.phone.value,
          ...this.phoneData,
          rating: this.creditRating.value,
          rating_reason: this.creditRatingReason.value,
          require_copies: this.currentDeliveryReqs.require_copies,
          require_email: this.currentDeliveryReqs.require_email,
          require_fax: this.currentDeliveryReqs.require_fax,
          require_online_submit: this.currentDeliveryReqs.require_online_submit,
          require_originals: this.currentDeliveryReqs.require_originals,
          state: this.state.value,
          status: this.bobtailStatus.value.toLowerCase(),
          zip: this.zip.value
        })).data

        // insert it into queue so it will show up immediately
        this.$store.commit('INSERT_USER_CREATE_QUEUE', {
          type: 'debtor',
          ...newDebtor
        })

        if (this.mc.value && this.dot.value) {
          try {
            await Broker.create({
              authority_date: moment(this.authorityDate.value),
              authority_status: this.authorityStatus.value === 'Authorized'
                ? 'active'
                : 'inactive',
              debtor_id: newDebtor.id,
              dot: this.dot.value,
              mc: doctoredMC,
            })
          }
          catch (error) {
            this.captureSentryEvent(
              'Create Debtor "Create Broker"',
              {
                clientId: this.id,
                config: error.config,
                data: this.$data,
                details: error,
                props: this.$props,
                response: error.response,
              }
            )
            this.CError(error)
            this.requestFailure({ message: 'There was an issue making the debtor a broker' })
          }
        }

        this.requestSuccess({ message: 'Debtor Successfully Created!' })
        this.$router.push({
          name: 'debtor',
          params: {
            id: newDebtor.id.toString()
          },
        })
      }
      catch (error) {
        this.captureSentryEvent(
          'Create Debtor "Create"',
          {
            clientId: this.id,
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        let message = 'There was an issue creating a new debtor'
        if (error.response && error.response.data && error.response.data.message) {
          if (displayErrorMessage(error.response.data.message)) {
            message = `There was an error: ${error.response.data.message}`
          }
        }
        this.requestFailure({ message })
      }
      this.buttonDisabled = false
    },
  }
}
</script>

<style lang="sass">
.CreateDebtor
  background-color: $white
  border: $border
  border-radius: $border-radius
  box-shadow: $container-shadow
  margin-right: rem(50px)
  margin-top: rem(52px)
  padding: rem(44px) rem(47px) rem(21px) rem(29px)

  // Allows overriding the style for Delivery Options header to match rest of page
  .BaseDeliveryRequirements

    &__heading
      font-size: rem(16px)
      margin-bottom: rem(15px)

  .BaseTextarea
    width: rem(350px)

  &__select
    width: rem(200px)

    .vs__selected
      &::before
        content: 'Status:'
        padding-right: rem(4px)

    &--invalid
      border: $border
      border-color: $danger
      border-radius: $border-radius

  &__select-state
    width: rem(200px)

    &--invalid
      border: $border
      border-color: $danger
      border-radius: $border-radius

  &__credit-select
    width: rem(180px)

    .vs__selected
      &::before
        content: 'Rating:'
        padding-right: rem(4px)

    &--invalid
      border: $border
      border-color: $danger
      border-radius: $border-radius

  &__header
    margin-bottom: rem(41px)

  &__input

    &--long
      width: rem(350px)

    &--medium
      width: rem(250px)

    &--short
      width: rem(175px)

  &__save-button
    width: rem(326px)

  &__save-wrap
    margin-bottom: rem(100px)
    margin-top: rem(50px)

  &__section

    &-authority
      margin-top: rem(31px)

    &-company
      margin-top: rem(41px)

    &-credit
      margin-top: rem(31px)

    &-delivery
      margin-bottom: rem(50px)
      margin-top: rem(31px)

</style>