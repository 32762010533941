var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CreateDebtor" },
    [
      _c("div", { staticClass: "column" }, [
        _c(
          "div",
          { staticClass: "CreateDebtor__header row row--justify-between" },
          [
            _c("label", { staticClass: "fs-22 fw-med" }, [
              _vm._v("New Debtor")
            ]),
            !_vm.prod
              ? _c(
                  "base-button",
                  {
                    staticClass: "bg-blue fc-white",
                    on: { click: _vm.hydrateFakeDebtorForTesting }
                  },
                  [_vm._v("\n        SHAZAM!!!\n      ")]
                )
              : _vm._e()
          ],
          1
        ),
        _c("hr", { staticClass: "Divider" }),
        _c("div", { staticClass: "CreateDebtor__section-company column" }, [
          _c("label", { staticClass: "fs-16 fw-med mb-15" }, [
            _vm._v("Company")
          ]),
          _c(
            "div",
            { staticClass: "row row--wrap" },
            [
              _c(
                "base-input",
                {
                  staticClass: "CreateDebtor__input--medium mr-9 mb-12",
                  attrs: {
                    instructions: _vm.instructions.SHORTENED_NAME_INSTRUCTION,
                    label: true,
                    "label-for": "name",
                    valid: _vm.name.valid
                  },
                  model: {
                    value: _vm.name.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.name,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "name.value"
                  }
                },
                [_vm._v("\n          Name\n        ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-9 mb-25",
                  attrs: {
                    instructions: _vm.instructions.DBA_DEBTOR_INSTRUCTION,
                    label: true,
                    "label-for": "dba",
                    valid: _vm.dba.valid
                  },
                  model: {
                    value: _vm.dba.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.dba,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "dba.value"
                  }
                },
                [_vm._v("\n          DBA\n        ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateDebtor__input--long mr-9 mb-12",
                  attrs: {
                    instructions: _vm.instructions.ADDRESS_INSTRUCTION,
                    label: true,
                    "label-for": "address",
                    valid: _vm.address.valid
                  },
                  model: {
                    value: _vm.address.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.address,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "address.value"
                  }
                },
                [_vm._v("\n          Address\n        ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateDebtor__input--short mr-9 mb-12",
                  attrs: {
                    instructions: _vm.instructions.ADDRESS_2_INSTRUCTION,
                    label: true,
                    "label-for": "address 2",
                    valid: _vm.address_2.valid
                  },
                  model: {
                    value: _vm.address_2.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.address_2,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "address_2.value"
                  }
                },
                [_vm._v("\n          Address 2\n        ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateDebtor__input--short mr-9 mb-12",
                  attrs: {
                    instructions: _vm.instructions.CITY_INSTRUCTION,
                    label: true,
                    "label-for": "city",
                    valid: _vm.city.valid
                  },
                  model: {
                    value: _vm.city.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.city,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "city.value"
                  }
                },
                [_vm._v("\n          City\n        ")]
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto mb-12" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "BaseInput__label uppercase",
                      attrs: { for: "state" }
                    },
                    [_vm._v("\n            State\n          ")]
                  ),
                  _c("v-select", {
                    class: [
                      "CreateDebtor__select-state mr-9",
                      {
                        "CreateDebtor__select-state--invalid": !_vm.state.valid
                      }
                    ],
                    attrs: {
                      "aria-label": "Select input to choose state",
                      id: "state",
                      clearable: false,
                      instructions: _vm.instructions.STATE_INSTRUCTION,
                      options: _vm.state.options,
                      placeholder: "State:"
                    },
                    model: {
                      value: _vm.state.value,
                      callback: function($$v) {
                        _vm.$set(_vm.state, "value", $$v)
                      },
                      expression: "state.value"
                    }
                  })
                ],
                1
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateDebtor__input--short mr-9 mb-12",
                  attrs: {
                    instructions: _vm.instructions.ZIP_INSTRUCTION,
                    label: true,
                    "label-for": "zip",
                    valid: _vm.zip.valid
                  },
                  model: {
                    value: _vm.zip.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.zip,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "zip.value"
                  }
                },
                [_vm._v("\n          Zip\n        ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateDebtor__input--short mr-9 mb-12",
                  attrs: {
                    instructions: _vm.phone.connected
                      ? _vm.instructions.PHONE_INSTRUCTION
                      : _vm.instructions.PHONE_CONNECTED_INSTRUCTION,
                    label: true,
                    "label-for": "phone",
                    valid: _vm.phone.valid && _vm.phone.connected
                  },
                  model: {
                    value: _vm.phone.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.phone,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "phone.value"
                  }
                },
                [_vm._v("\n          Phone\n        ")]
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto mb-12" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "BaseInput__label uppercase",
                      attrs: { for: "bobtail-status" }
                    },
                    [_vm._v("\n            Bobtail Status\n          ")]
                  ),
                  _c("v-select", {
                    staticClass: "CreateDebtor__select mr-9",
                    attrs: {
                      "aria-label": "Select input to choose bobtail status",
                      id: "bobtail-status",
                      clearable: false,
                      options: _vm.bobtailStatus.options,
                      placeholder: "Status:"
                    },
                    model: {
                      value: _vm.bobtailStatus.value,
                      callback: function($$v) {
                        _vm.$set(_vm.bobtailStatus, "value", $$v)
                      },
                      expression: "bobtailStatus.value"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "CreateDebtor__section-authority column" }, [
          _c("label", { staticClass: "fs-16 fw-med mb-15" }, [
            _vm._v("Authority")
          ]),
          _c(
            "div",
            { staticClass: "row row--wrap" },
            [
              _c(
                "base-input",
                {
                  staticClass: "CreateDebtor__input--short mr-9 mb-12",
                  attrs: {
                    instructions: _vm.mc.unique
                      ? _vm.instructions.MC_INSTRUCTION
                      : _vm.instructions.MC_UNIQUE_INSTRUCTION,
                    label: true,
                    "label-for": "mc-number",
                    valid: _vm.mc.valid && _vm.mc.unique
                  },
                  model: {
                    value: _vm.mc.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.mc,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "mc.value"
                  }
                },
                [_vm._v("\n          MC #\n        ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateDebtor__input--short mr-9 mb-12",
                  attrs: {
                    instructions: _vm.dot.unique
                      ? _vm.instructions.DOT_INSTRUCTION
                      : _vm.instructions.DOT_UNIQUE_INSTRUCTION,
                    label: true,
                    "label-for": "dot-number",
                    valid: _vm.dot.valid && _vm.dot.unique
                  },
                  model: {
                    value: _vm.dot.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.dot,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "dot.value"
                  }
                },
                [_vm._v("\n          DOT #\n        ")]
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "fc-light fs-12 mb-7 uppercase",
                      attrs: { for: "authority-date" }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.authorityDate.valid
                              ? "Authority Date"
                              : _vm.instructions.AUTHORITY_DATE_INSTRUCTION
                          ) +
                          "\n          "
                      )
                    ]
                  ),
                  _c("base-date-picker", {
                    staticClass: "mr-9 mb-12",
                    attrs: {
                      id: "authority-date",
                      valid: _vm.authorityDate.valid
                    },
                    model: {
                      value: _vm.authorityDate.value,
                      callback: function($$v) {
                        _vm.$set(_vm.authorityDate, "value", $$v)
                      },
                      expression: "authorityDate.value"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto mb-12" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "BaseInput__label uppercase",
                      attrs: { for: "authority-status" }
                    },
                    [_vm._v("\n            Authority Status\n          ")]
                  ),
                  _c("v-select", {
                    staticClass: "CreateDebtor__select mr-9",
                    attrs: {
                      "aria-label": "Select input to choose authority status",
                      id: "authority-status",
                      clearable: false,
                      options: _vm.authorityStatus.options,
                      placeholder: "Status:"
                    },
                    model: {
                      value: _vm.authorityStatus.value,
                      callback: function($$v) {
                        _vm.$set(_vm.authorityStatus, "value", $$v)
                      },
                      expression: "authorityStatus.value"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "CreateDebtor__section-credit column" }, [
          _c("label", { staticClass: "fs-16 fw-med mb-15" }, [
            _vm._v("Credit")
          ]),
          _c(
            "div",
            { staticClass: "row row--wrap" },
            [
              _c(
                "div",
                { staticClass: "column column--width-auto mb-12" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "BaseInput__label uppercase",
                      attrs: { for: "corporation-type" }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.creditRating.valid
                              ? "Credit Rating"
                              : "Select a credit rating"
                          ) +
                          "\n          "
                      )
                    ]
                  ),
                  _c("v-select", {
                    class: [
                      "CreateDebtor__credit-select mr-9",
                      {
                        "CreateDebtor__credit-select--invalid": !_vm
                          .creditRating.valid
                      }
                    ],
                    attrs: {
                      "aria-label": "Select input to choose credit rating",
                      id: "rating",
                      clearable: false,
                      options: _vm.creditRating.options,
                      placeholder: "Rating:"
                    },
                    model: {
                      value: _vm.creditRating.value,
                      callback: function($$v) {
                        _vm.$set(_vm.creditRating, "value", $$v)
                      },
                      expression: "creditRating.value"
                    }
                  })
                ],
                1
              ),
              _c(
                "base-textarea",
                {
                  staticClass: "mb-10",
                  attrs: {
                    instructions:
                      _vm.instructions.CREDIT_RATING_REASON_INSTRUCTION,
                    label: true,
                    "label-for": "credit-rating-reason",
                    rows: "2",
                    valid: _vm.creditRatingReason.valid
                  },
                  model: {
                    value: _vm.creditRatingReason.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.creditRatingReason,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "creditRatingReason.value"
                  }
                },
                [_vm._v("\n          Rating Reason\n        ")]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "CreateDebtor__section-delivery column" }, [
          _c(
            "div",
            { staticClass: "row row--wrap" },
            [
              _c(
                "transition",
                { attrs: { name: "Transition__fade" } },
                [
                  _vm.currentDeliveryReqs
                    ? _c("base-delivery-requirements", {
                        attrs: { debtor: _vm.currentDeliveryReqs },
                        on: { save: _vm.setCurrentDeliveryReqs }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("hr", { staticClass: "Divider" }),
        _c(
          "div",
          {
            staticClass: "CreateDebtor__save-wrap column column--align-center"
          },
          [
            _c(
              "base-button",
              {
                staticClass: "CreateDebtor__save-button bg-blue fc-white",
                attrs: {
                  id: "Testing__CreateDebtorSubmitBtn",
                  disabled: _vm.buttonDisabled || !_vm.isValid
                },
                on: { click: _vm.submitDebtor }
              },
              [_vm._v("\n        Save Debtor\n      ")]
            )
          ],
          1
        )
      ]),
      _c(
        "transition",
        { attrs: { name: "Transition__fade" } },
        [
          _vm.nonUniqueDebtorMCModalVisible
            ? _c("base-modal", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n        A broker with this MC # already exists in the database. Are you sure you want to add them?\n      "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-light fc-white mr-8",
                              on: {
                                click: function($event) {
                                  _vm.nonUniqueDebtorMCModalVisible = false
                                }
                              }
                            },
                            [_vm._v("\n          Cancel\n        ")]
                          ),
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-blue fc-white",
                              on: {
                                click: function($event) {
                                  return _vm.submitDebtor(true)
                                }
                              }
                            },
                            [_vm._v("\n          Yes, add them\n        ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1819991168
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }