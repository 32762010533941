<template>
  <div class="BaseTextarea">
    <label
      v-if="label"
      class="BaseTextarea__label fc-light fs-12 uppercase"
      :for="labelFor"
    >
      <slot v-if="valid" />
      {{ valid ? null : instructions }}
    </label>
    <textarea
      @input="$emit('input', $event.target.value)"
      autocomplete="off"
      :class="['BaseTextarea__input fs-14', { 'BaseTextarea__input--error': !valid }]"
      :id="labelFor"
      :maxLength="maxlength"
      :placeholder="placeholder"
      :rows="rows"
      :value="value"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseTextarea',

  props: {
    instructions: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelFor: {
      type: String,
      required: false,
      default: null,
    },
    maxlength: {
      type: Number,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    rows: {
      type: String,
      required: false,
      default: '3',
    },
    valid: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
  }
}
</script>

<style lang="sass">
.BaseTextarea
  +flex($direction: column)
  $block: &

  &__input
    background-color: $white
    border: $border
    border-radius: $border-radius
    max-width: 100%
    outline: none
    padding: rem(8px) 1rem
    transition: all .15s ease 0s
    width: 100%
    white-space: initial

    &:focus,
    &:hover
      border-color: $branding

    &::placeholder
      color: #AFB1B5
      font-size: inherit

    &--error
      border-color: $danger

  &__label,
  &__label span
    color: #9EA0A5
    margin-bottom: rem(7px)
    user-select: none
</style>