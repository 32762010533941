var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseTextarea" }, [
    _vm.label
      ? _c(
          "label",
          {
            staticClass: "BaseTextarea__label fc-light fs-12 uppercase",
            attrs: { for: _vm.labelFor }
          },
          [
            _vm.valid ? _vm._t("default") : _vm._e(),
            _vm._v(
              "\n    " + _vm._s(_vm.valid ? null : _vm.instructions) + "\n  "
            )
          ],
          2
        )
      : _vm._e(),
    _c("textarea", {
      class: [
        "BaseTextarea__input fs-14",
        { "BaseTextarea__input--error": !_vm.valid }
      ],
      attrs: {
        autocomplete: "off",
        id: _vm.labelFor,
        maxLength: _vm.maxlength,
        placeholder: _vm.placeholder,
        rows: _vm.rows
      },
      domProps: { value: _vm.value },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event.target.value)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }